import {
  Anchor,
  Button,
  Checkbox,
  Container,
  Group,
  Input,
  Paper,
  Text,
  Title,
} from "@mantine/core";
import { useForm, zodResolver } from "@mantine/form";
import { useAtom } from "jotai";
import Link from "next/link";
import Router from "next/router";
import { useState } from "react";
import { useSignIn } from "react-supabase";
import { z } from "zod";
import { userAtom } from "../../contexts/UserContext";
import { supabase } from "../../utils/supabase";
import { loadingAtom } from "~/contexts/LoadingContext";

const formSchema = z.object({
  email: z.string().email({ message: "Adresse email incorrecte" }),
  password: z.string().min(2, { message: "Veuillez rentrer un mot de passe" }),
});

export default function LoginPage() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [, setUser] = useAtom(userAtom);
  const [, setLoading] = useAtom(loadingAtom);

  const [{ fetching }, signIn] = useSignIn();

  const form = useForm({
    initialValues: {
      email: "",
      password: "",
    },

    validate: zodResolver(formSchema),
  });

  async function signInWithEmail() {
    const { data, error } = await supabase.auth.signInWithPassword({
      email,
      password,
    });

    if (error) return alert(error);
    if (fetching) return <div>Signing in</div>;
    if (data) {
      setLoading(true);
      if (data && setUser) {
        setUser(data.user);
      }
      Router.push("/home");
    }
  }

  return (
    <Container size={420} my={40}>
      <Title
        align="center"
        sx={(theme) => ({
          fontFamily: `Greycliff CF, ${theme.fontFamily}`,
          fontWeight: 900,
          color: "white",
        })}
      >
        Ravi de vous revoir !
      </Title>
      <Text color="white" size="sm" align="center" mt={5}>
        Vous n&apos;avez pas encore de compte ?{" "}
        <Link href={"/onboarding"} passHref>
          <Anchor component="a" size="sm">
            Créer un compte
          </Anchor>
        </Link>
      </Text>

      <Paper withBorder shadow="md" p={30} mt={30} radius="md">
        <form onSubmit={form.onSubmit((values) => console.log(values))}>
          <Input.Wrapper
            id="email"
            label="Adresse email"
            required
            {...form.getInputProps("email")}
          >
            <Input
              placeholder="Email"
              value={form.values.email}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setEmail(event.currentTarget.value);
                form.setFieldValue("email", event.currentTarget.value);
              }}
            />
          </Input.Wrapper>
          <Input.Wrapper
            id="password"
            label="Mot de passe"
            required
            {...form.getInputProps("password")}
          >
            <Input
              placeholder="Mot de passe"
              value={form.values.password}
              type="password"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setPassword(event.currentTarget.value);
                form.setFieldValue("password", event.currentTarget.value);
              }}
            />
          </Input.Wrapper>
        </form>

        <Group position="apart" mt="md">
          <Checkbox label="Se souvenir de moi" />
          <Anchor<"a">
            onClick={(event) => event.preventDefault()}
            href="#"
            size="sm"
          >
            Mot de passe oublié ?
          </Anchor>
        </Group>
        <Button
          fullWidth
          mt="xl"
          radius={"md"}
          onClick={() => signInWithEmail()}
        >
          Se connecter
        </Button>
      </Paper>
    </Container>
  );
}
